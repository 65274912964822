import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { inventoryStatusEnum } from '../../../../common/constants';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  noInventory:{
    margin: '10px 0 0 10px'
  }
}));

const TagTable = props => {
  const {
    className,
    entries,
    totalItems,
    rowsPerPage,
    page,
    onRowClick,
    setRowsPerPage,
    handlePageChange,
    ...rest 
  } = props;

  const classes = useStyles();

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const showStatus = (status) =>{
    switch (status) {
      case inventoryStatusEnum.COMPLETED:
        return 'Completada'

      case inventoryStatusEnum.IN_PROGESS:
        return 'En Progreso'

      default:
        break;
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Bodega</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Responsable</TableCell>
                  <TableCell>Total Items</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  entries.length !== 0 ?
                    <>
                      {entries.slice(0, rowsPerPage).map(entry => {
                        const entryData = entry.data;
                        const entryID = entry.id
                        return (
                          <TableRow
                            className={classes.tableRow}
                            hover
                            key={entryID}
                            onClick={onRowClick(entryID)}
                          >
                            <TableCell>
                              {moment(entryData.fecha,'DD/MM/YYYY').format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell>{entryData.bodega}</TableCell>
                            <TableCell>{showStatus(entryData.status)}</TableCell>
                            <TableCell>{entryData.responsable}</TableCell>
                            <TableCell>{entryData.totalItems}</TableCell>
                          </TableRow>
                        )
                      })}
                    </>
                    :
                    <>
                      <TableRow className={classes.tableRow}>
                        <Typography className={classes.noInventory}>
                          No hay Ingresos/Egresos a mostrar
                        </Typography>
                      </TableRow>
                    </>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalItems}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

TagTable.propTypes = {
  className: PropTypes.string,
  entries: PropTypes.array.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
};

export default TagTable;
