import './assets/scss/index.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createBrowserHistory } from 'history';
import React, { Component } from 'react';
import { Chart } from 'react-chartjs-2';
import { Router } from 'react-router-dom';
import validate from 'validate.js';

import validators from './common/validators';
import { chartjs } from './helpers';
import Routes from './Routes';
import theme from './theme';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={browserHistory}>
            <Routes />
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}
