import { Avatar, Card, CardContent, CircularProgress, Grid, Typography } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  circularProgress: {
    margin: theme.spacing(4),
    color: theme.palette.white
  }
}));

const TotalProfit = props => {
  const { className, ventas, loading, ...rest } = props;

  const classes = useStyles();

  const formattedVentas = ventas ? numeral(ventas).format('0,0.00') : null;
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
            >
              VENTAS TOTALES
            </Typography>
            {loading && <CircularProgress className={classes.circularProgress} />}
            {
              !loading && 
              <Typography
                color="inherit"
                variant="h3"
              >
                {`$ ${formattedVentas}`}
              </Typography>
            }
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalProfit.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  ventas: PropTypes.number,
};

export default TotalProfit;
