import { Button , CircularProgress, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { SearchInput } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { newTagUrl } from 'views/Inventory/urls';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  mt5 : {
    marginTop: '20px'
  }
}));

const TagToolbar = props => {
  const { className, searchProduct , refreshProducts ,messageRefresh, setShowRefresh ,showRefresh, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Link to={newTagUrl}>
          <Button
            color="primary"
            variant="contained"
          >
            Nueva Impresión de etiquetas
          </Button>
        </Link>
      </div>
      <div className={classes.row}>
        <SearchInput
          onChange={e => searchProduct(e.target.value)}
          className={classes.searchInput}
          placeholder="Buscar..."
        />
        <IconButton
          onClick={() => {
            refreshProducts();
            setShowRefresh(true);
          }}
        >
          <RefreshIcon />
        </IconButton>
      </div>
      <div className={classes.mt5}>
        { 
          showRefresh ?
            <>
              <CircularProgress color="secondary" />
            </> 
            : 
            <Typography
              variant="body1"
              color="secondary"
            >{messageRefresh}</Typography>
        }
      </div>
    </div>
  );
};

TagToolbar.propTypes = {
  className: PropTypes.string,
  messageRefresh: PropTypes.string.isRequired,
  refreshProducts: PropTypes.func.isRequired,
  searchProduct: PropTypes.func.isRequired,
  setShowRefresh: PropTypes.func.isRequired,
  showRefresh: PropTypes.bool,
};

export default TagToolbar;
