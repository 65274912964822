import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableSortLabel,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { beautifyName } from '../../../../lib/format';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { inventoryStatusEnum } from '../../../../common/constants';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  noInventory:{
    margin: '10px 0 0 10px'
  }
}));

const InventoryTable = props => {
  const {
    className,
    entries,
    totalItems,
    rowsPerPage,
    page,
    onClickRow,
    setRowsPerPage,
    handlePageChange,
    isMasterCheckbox,
    setMasterCheckbox,
    selectedRows,
    selectRowIndex,
    isDescending,
    onUpdateSort,
    ...rest
  } = props;

  const classes = useStyles();
  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const handleSortDirectionChange = () => {
    onUpdateSort();
  }

  const isRowSelected = (index) => {
    const foundIndex = selectedRows.findIndex((row) => row === index);
    return foundIndex !== -1;
  }
  const showStatus = (status) =>{
    switch (status) {
      case inventoryStatusEnum.COMPLETED:
        return 'Completada'
      case inventoryStatusEnum.IN_PROGESS:
        return 'En Progreso'
      default:
        break;
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={isMasterCheckbox}
                      onChange={() => {
                        setMasterCheckbox(!isMasterCheckbox);
                      }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active
                      direction={isDescending ? 'desc' : 'asc'}
                      onClick={handleSortDirectionChange}
                    >
                      Guardado
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Bodega</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Responsable</TableCell>
                  <TableCell>Total Items</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {entries.length !== 0 ?
                  <>
                    {entries.slice(0, rowsPerPage).map((entry, index) => {
                      const entryData = entry.data;
                      const entryID = entry.id
                      return (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={entryID}
                          onClick={(event, id) => {
                            const isCheckbox = event.target.type === 'checkbox';
                            if (!isCheckbox) {
                              onClickRow(entryID);
                            }
                          }}
                        >
                          <TableCell>
                            <Checkbox
                              checked={isRowSelected(index)}
                              onChange={() => {
                                selectRowIndex(index);
                              }}
                              color="primary"
                            />
                          </TableCell>
                          <TableCell>
                            {moment(entryData.lastSaved).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell>{beautifyName(entryData.bodega)}</TableCell>
                          <TableCell>{showStatus(entryData.status)}</TableCell>
                          <TableCell>{beautifyName(entryData.responsable)}</TableCell>
                          <TableCell>{entryData.totalItems}</TableCell>
                        </TableRow>
                      )
                    })}
                  </>
                  :
                  <>
                    <TableRow className={classes.tableRow}>
                      <Typography className={classes.noInventory}>
                        No hay tomas físicas a mostrar
                      </Typography>
                    </TableRow>
                  </>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalItems}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

InventoryTable.propTypes = {
  className: PropTypes.string,
  entries: PropTypes.array.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  isDescending: PropTypes.bool.isRequired,
  isMasterCheckbox: PropTypes.bool.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onUpdateSort: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  selectRowIndex: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  setMasterCheckbox: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
};

export default InventoryTable;
