import { useEffect, useState } from 'react';

const BASE_URL = 'https://de224873-d6e8-4a45-8520-06aac1a913b7.mock.pstmn.io/';

function useFetch(path) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  async function fetchUrl() {
    // TODO[sebastian]: Add authentication headers
    const response = await fetch(`${BASE_URL}${path}`, {
      headers: {
        'Authorization': 'Token CACA'
      }
    });
    const json = await response.json();
    setData(json);
    setLoading(false);
  }
  
  useEffect(() => {
    fetchUrl();
  }, []);
  return [data, loading];
}

function usePost(path, body) {
  const [ response, setResponse ] = useState(null);
  const [ loading, setLoading ] = useState(true);

  async function postURL() {
    const response = await fetch(`${BASE_URL}${path}`, {
      method: 'POST',
      body: JSON.stringify(body)
    });
    const json = await response.json();
    setResponse(json);
    setLoading(false);
  }

  useEffect(() => {
    postURL()
  });

  return [ response, loading];
}

export { useFetch, usePost };