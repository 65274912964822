/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { VARIANT_TYPE , COLOR_CONSTANT , SIZE_CONSTANT } from 'views/Inventory/utils/constants';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const TagTable_list = props => {
  const {
    className,
    products,
    totalItems,
    rowsPerPage,
    variants,
    page,
    onRowClick,
    entries,
    setRowsPerPage,
    handlePageChange,
    ...rest 
  } = props;

  const classes = useStyles();

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const getTotalTagsFromProduct = (productId) =>{
    let quantity = 0;
    let quantityBoxes
    let quantityProducts
    entries.map(entry =>{
      quantityBoxes = entry.data.items.boxes.find(box => box.product.id === productId);
      quantityProducts = entry.data.items.products.find(product => product.product.id === productId);
    })
    if(quantityBoxes && quantityProducts){
      quantity = Number(quantityBoxes.quantity) + Number(quantityProducts.quantity)
    }
    return quantity;
  }

  React.useEffect(()=>{

  },[])

  const getColorAndSize = (product) => {
    let productType = '';
    if(product.data){
      productType = product.data.tipo
    }else{
      productType = product.tipo;
    }
    let color = '';
    let size = '';
    if(productType === VARIANT_TYPE){
      product.detalle_variantes.map( detalleVariante =>{
        const variant = variants.find( variant => variant.id === detalleVariante.variante_id )
        if(variant.nombre === COLOR_CONSTANT){
          color = variant.valores.find( color => color.id === detalleVariante.valor_id).valor;
        }else if(variant.nombre === SIZE_CONSTANT){
          size = variant.valores.find( color => color.id === detalleVariante.valor_id).valor;
        }
      })
    }
    return {
      color,
      size
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Código</TableCell>
                  <TableCell>Color</TableCell>
                  <TableCell>Talla</TableCell>
                  <TableCell>Cantidad</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.length !== 0 ?
                  products.slice(0, rowsPerPage).map(product => {
                    
                    if(product.data){
                      product = product.data
                    }
                    const { color,size } = getColorAndSize(product);
                    const quantity = getTotalTagsFromProduct(product.id)
                   
                    return (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={product.id}
                        onClick={onRowClick(product.id)}
                      >
                        <TableCell>{product.nombre}</TableCell>
                        <TableCell>{product.codigo}</TableCell>
                        <TableCell>{color ? color : 'N/A'}</TableCell>
                        <TableCell>{size ? size : 'N/A'}</TableCell>
                        <TableCell>{quantity}</TableCell>
                      </TableRow>
                    )
                  }) :
                  <TableRow>
                    <TableCell>{'No hay productos a mostrar'}</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalItems}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

TagTable_list.propTypes = {
  className: PropTypes.string,
  handlePageChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  products: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
  varaints: PropTypes.array.isRequired,
};

export default TagTable_list;
