import { useState } from 'react';
import { singInAction, singUpAction } from './../../actions/auth.actions';

import { useDispatch } from 'react-redux';

const useSignUpForm = (history) => {
  const dispatch = useDispatch();
  const signUp = (user,history) => dispatch( singUpAction(user,history) );
  const signIn = (user,history) => dispatch( singInAction(user,history) );
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const handleInputChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (!formState.isValid) {
      return;
    }
    if(event.target.name === 'signIn'){
      signIn(formState.values,history)
    }else{
      signUp(formState.values,history)
    }
  }

  return {
    handleSubmit,
    handleInputChange,
    formState,
    setFormState
  }
}

export { useSignUpForm };
