import { AuthContextProvider } from 'lib';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import {store,persistor} from './store';
import { PersistGate } from 'redux-persist/integration/react';



ReactDOM.render(
  <AuthContextProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </AuthContextProvider>
  , document.getElementById('root'));

serviceWorker.unregister();
