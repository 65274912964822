export function beautifyName(name) {
  const tokens = name.split(' ');
  const finalTokens = [];

  for (const tok of tokens) {
    const beautifyName = `${tok[0].toUpperCase()}${tok.slice(1).toLowerCase()}`;
    finalTokens.push(beautifyName);
  }

  return finalTokens.join(' ');
}
