import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { withRouter } from 'react-router';
import ProvidersTable from './components/Providerstable';
import ProvidersToolbar from './components/ProvidersToolbar';
import { getProviders, loadProvidersToFirestore } from './utils/functions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: 0,
    padding: '0 20px 0 20px'
  }
}));

const ProvidersList = () => {
  const classes = useStyles();
  const [rowsPerPage,setRowsPerPage] = React.useState(10);
  const [totalItems,setTotalItems] = React.useState(0)
  const [providers,setProviders] = React.useState([]);
  const [showRefresh,setShowRefresh] = React.useState(false);
  const [messageRefresh,setMessageRefresh] = React.useState('')
  const [page, setPage] = React.useState(0);
  const [filters,setFilters] = React.useState({
    RUC: '',
    providerName: ''
  });
  
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const searchUsingFilters = async () =>{
    const { data } = await getProviders(rowsPerPage,page,'',filters);
    setProviders(data.data)
    setTotalItems(data.totalItems)
  }

  const getProvidersFromDb = async () => {
    const idLastProvider = providers.length !== 0 ? providers[providers.length-1].id :'';
    const { data } = await getProviders(rowsPerPage,page,idLastProvider,filters);
    setProviders(data.data)
    setTotalItems(data.totalItems)
  }

  const clearFilters = async () => {
    const obj = {
      RUC: '',
      providerName: ''
    } 
    setFilters(obj)
    const { data } = await getProviders(rowsPerPage,page,'',obj);
    setProviders(data.data)
    setTotalItems(data.totalItems)
  }

  React.useEffect(()=>{
    getProvidersFromDb();
  },[rowsPerPage,page]);

  React.useEffect(()=>{
    setTimeout(()=>{
      setMessageRefresh('')
    },5000)
  },[messageRefresh])

  const refreshProvidersFirestore = async () =>{
    const data = await loadProvidersToFirestore();
    if(data.status === 200){
      setShowRefresh(false);
      getProvidersFromDb();
      setMessageRefresh('Se han actualizado los productos');
    }else{
      setShowRefresh(true)
    }
  }

  return ( 
    <>
      <div className={classes.root}>
        <ProvidersToolbar
          setFilters={setFilters}
          filters={filters}
          searchUsingFilters={searchUsingFilters}
          clearFilters={clearFilters}
          refreshProviders={() =>{
            refreshProvidersFirestore();
          }}
          showRefresh={showRefresh}
          setShowRefresh={setShowRefresh}
          messageRefresh={messageRefresh}
        />
      </div>
      <div className={classes.content}>
        <ProvidersTable
          entries={providers}
          totalItems={totalItems}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </>
  );
}
 
export default withRouter(ProvidersList);