import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { COLOR_CONSTANT, SIZE_CONSTANT } from 'views/Inventory/utils/constants';

const TagTable = ({state,page,rowsPerPage,changeQuantity,tabIndex,variants}) => {
  return ( 
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Código</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Talla</TableCell>
            <TableCell>Cantidad</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(tabIndex === 0 ?
            state.items.products
            :state.items.boxes
          ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(entry => {
            let color = '';
            let size = '';
            entry.product.detalle_variantes.map( detalleVariante =>{
              const variant = variants.find( variant => variant.id === detalleVariante.variante_id )
              if(variant.nombre === COLOR_CONSTANT){
                color = variant.valores.find( color => color.id === detalleVariante.valor_id).valor;
              }else if(variant.nombre === SIZE_CONSTANT){
                size = variant.valores.find( color => color.id === detalleVariante.valor_id).valor;
              }
            })
            return (
              <TableRow
                hover
                key={entry.id}
              >
                <TableCell>{entry.product.nombre}</TableCell>
                <TableCell>{entry.product.codigo}</TableCell>
                <TableCell>{color}</TableCell>
                <TableCell>{size}</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    id="outlined-basic"
                    defaultValue={0}
                    variant="outlined"
                    onChange={(e)=>changeQuantity(entry.product.id,e.target.value,tabIndex === 0 ? 'product' : 'box')}
                  />
                </TableCell>
              </TableRow>
            ) 
          })}
        </TableBody>
      </Table>
    </div>
  );
}

TagTable.propTypes = {
  changeQuantity: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired.length,
  rowsPerPage: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired,
  variants: PropTypes.array.isRequired,
}
 
export default TagTable;