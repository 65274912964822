/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable quotes */
import { Button, Card, CardActions, CardContent, Select, TablePagination, TextField } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, useTheme } from '@material-ui/styles';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';

import PerchaTable from '../../components/PerchaTable';
import SearchInput from '../../components/SearchInput';
import { inventarioTomaFisica } from '../../firebase/collections';
import { saveInventoryAction } from './../../actions/Inventory.actions';
import { inventoryStatusEnum, inventoryTypeEnum } from './../../common/constants';
import InventoryTable from './../../components/inventoryTable';
import { InventoryFooter } from './components/Inventory';
import TabPanel from './TabPanel';
import { inventoryUrl } from './urls';
import { saveDocument, updateDocument } from './utils/firestoreFunctions';
import { groupBarcodeItems } from './utils/inventory';

const today = new Date();


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  content: {
    marginTop: theme.spacing(2)
  },
  titleContainer: {
    paddingBottom: theme.spacing(5)
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  formItem: {
    marginBottom: '20px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlLarge: {
    margin: theme.spacing(2),
    width: '100%',
    marginLeft: 0
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  perchaButton: {
    marginTop: theme.spacing(2)
  },
  searchInput: {
    width: '100%',
    display: 'block',
    flexBasis: '42px'
  }
}));


const NewNativeStockCount = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [ percha, setPercha ] = React.useState(1);
  const [ disabledPerchaButton, setDisabledPerchabutton ] = React.useState(true);
  const [successSaved,setSuccessSaved] = React.useState(false);
  const [disableUndo,setDisableUndo] = React.useState(true);
  const [errorMsg,setErrorMsg] = React.useState('');
  const [docID,setDocID] = React.useState('');
  const [canSave,setCanSave] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [state, setState ] = React.useState({
    fecha: `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`,
    bodega: '',
    responsable: '',
    query: '',
    items: [],
    totalItems: 0,
    lastSaved: 0,
    type: inventoryTypeEnum.TOMA_FISICA,
    status: inventoryStatusEnum.IN_PROGESS
  });
  const [diableFields,setDisableFields] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  }
  const handleTabChangeIndex = (index) => {
    setValue(index);
  }
  
  const handleDeletePerchaRow = (percha) => {
    // Remove from items
    const newItems = [...state.items]
      .filter((item) => item.percha !== percha);

    setState({
      ...state,
      items: newItems,
      totalItems: newItems.length
    });
    setPercha(percha - 1);
  };
  const handleEnter = () => {
    const value = state.query;
    const newItems = [...state.items];
    const upperValue = value.toUpperCase();
    setDisableUndo(false);
    newItems.push({
      barcode: upperValue,
      updatedAt: Date.now(),
      percha
    });

    setCanSave(true);
    setDisabledPerchabutton(false);
    setState({
      ...state,
      items: newItems,
      totalItems: state.totalItems + 1,
      query: ''
    });
    return;
  }


  React.useEffect(()=>{
    setDisableFields(state.status === inventoryStatusEnum.COMPLETED)
  },[state.status]);

  const groupItemsByPercha = (items) => {
    const finalItems = items.reduce((prev, nextItem) => {
      const newItems = [...prev];
      const percha = nextItem.percha;
      const foundIndex = prev.findIndex((item) => item && item.percha === percha);

      if (foundIndex === -1) {
        newItems.push({
          updatedAt: nextItem.updatedAt,
          percha,
          count: 1
        });
      } else {
        // Update the field
        const currentValue = newItems[foundIndex];
        newItems[foundIndex] = {
          updatedAt: currentValue.updatedAt > nextItem.updatedAt ? currentValue.updatedAt : nextItem.updatedAt,
          percha,
          count: currentValue.count + 1
        }
      }

      return newItems;
    }, []);

    return finalItems;
  }
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const validateData = () =>{
    if(state.items.length !== 0){
      setDisableUndo(false);
    }else{
      setDisableUndo(true);
    }
    setErrorMsg('');
    let err = false;
    if(!state.bodega){
      setErrorMsg('Por Favor llene Todos los Datos');
      err = true;
    }
    if(!state.responsable){
      setErrorMsg('Por Favor llene Todos los Datos');
      err = true;
    }
    if(!err){
      setErrorMsg('');
      setCanSave(false);
      return true;
    }else{
      return false;
    }
  }

  React.useEffect(()=>{
    setState({
      ...state,
      totalItems: state.items.length
    })
  },[state.items])

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
    setCanSave(true);
  };

  const downloadCSV = (csv, filename) => {
    var csvFile;
    var downloadLink;

    csvFile = new Blob([csv], {type: 'text/csv'});

    downloadLink = document.createElement('a');

    downloadLink.download = filename;

    downloadLink.href = window.URL.createObjectURL(csvFile);

    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);

    downloadLink.click();
  }

  const exportTableToCSV = (filename) => {
    var csv = [['Producto (Código)', 'Cantidad']];
    const exportItems = groupBarcodeItems(state.items);
    for (const item of exportItems) {
      const newRow = [item.barcode, item.count];
      csv.push(newRow);
    }

    downloadCSV(csv.join('\n'), filename);

    updateDocumentFromDb(true);
  }

  const saveDocumentFromDb = async () =>{

    const docRefId = await saveDocument(inventarioTomaFisica,{
      ...state,
      lastSaved: Date.now(),
      createdAt: Date.now()
    });
    if(docRefId){
      setTimeout(()=>{
        setSuccessSaved(false);
      },3000);
      setDocID(docRefId)
      setSuccessSaved(true);
      setCanSave(false);
      setDisableUndo(true)
    }
  
    dispatch(saveInventoryAction({
      ...state,
      lastSaved: Date.now(),
      createdAt: Date.now()
    }));
    setState({
      ...state,
      lastSaved: Date.now(),
      createdAt: Date.now()
    });
  }

  const updateDocumentFromDb = async (exportData) =>{
    
    let stateUpdated = {};
    
    if(validateData()){
      if(exportData){
        stateUpdated = {
          ...state,
          status: inventoryStatusEnum.COMPLETED ,
          lastSaved: Date.now()
        }
        setDisableFields(true);
      }else{
        stateUpdated = {
          ...state,
          lastSaved: Date.now()
        }
      }

      setCanSave(false);

      const result = await updateDocument(inventarioTomaFisica,docID,stateUpdated)
  
      if(result){
        setTimeout(()=>{
          setSuccessSaved(false);
        },4000);
        setSuccessSaved(true);
        setCanSave(false);
        setDisableUndo(true);
      }

      dispatch(saveInventoryAction({
        ...state,
        lastSaved: Date.now()
      }))
      setState({
        ...state,
        lastSaved: Date.now()
      });
    }
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Typography 
          variant="h2" 
          align="center"
          className={classes.titleContainer}
        >
          Toma Física
        </Typography>
        <Typography 
          variant="h5" 
          align="center"
          className={classes.titleContainer}
        >
          {`Items Totales: ${state.totalItems}`}
        </Typography>
        {
          successSaved ? <Alert severity="success">Cambios Guardados</Alert> : null
        }
        {
          errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null
        }
        <FormControl className={classes.formControl}>
          <div className={classes.row}>
            <TextField
              value={state.fecha} 
              label="Fecha" 
              disabled
            />
            <IconButton 
              aria-label="save" 
              disabled={diableFields || (state.items.length === 0 || !canSave)}
              color="primary"
              onClick={async () =>{
                setCanSave(false);
                setDisableUndo(true);
                if(validateData()){
                  if(!docID){
                    saveDocumentFromDb();
                  }else{
                    updateDocumentFromDb();
                  }
                  setState({
                    ...state,
                    lastSaved: Date.now()
                  });
                }
              }}
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              aria-label="save" 
              disabled={diableFields || (state.items.length === 0) || disableUndo}
              color="primary"
              onClick={() => {
                dispatch(saveInventoryAction({
                  ...state,
                  lastSaved: Date.now()
                }));
                setCanSave(false);
                setDisableUndo(true);
                setState({
                  ...state,
                  items: state.items.filter((newItem) => newItem.updatedAt <= state.lastSaved),
                  totalItems: state.items.length - 1
                })}
              }
            >
              <UndoIcon />
            </IconButton>
          </div>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="bodega-native-simple">Bodega</InputLabel>
          <Select
            native
            disabled={diableFields}
            value={state.bodega}
            onChange={handleChange}
            inputProps={{
              name: 'bodega',
              id: 'bodega-native',
            }}
          >
            <option 
              aria-label="None"
              value="" 
            />
            <option value={'PORTAL SHOPPING'}>Portal Shopping</option>
            <option value={'Bodega Matriz'}>Bodega Matriz</option>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="bodega-native-simple">Responsable</InputLabel>
          <Select
            native
            disabled={diableFields}
            value={state.responsable}
            onChange={handleChange}
            inputProps={{
              name: 'responsable',
              id: 'responsable-native',
            }}
          >
            <option 
              aria-label="None"
              value=""
            />
            <option value={'SUSANA SOLANO'}>Susana Solano</option>
            <option value={'NIXON ALVARADO'}>Nixon Alvarado</option>
            <option value={'ERIKA TOPON'}>Erika Topon</option>
          </Select>
        </FormControl>
        <FormControl className={classes.formControlLarge}>
          <SearchInput
            name="query"
            disabled={diableFields}
            className={classes.searchInput}
            value={state.query}
            onChange={handleChange}
            placeholder={'Código de Barras'}
            onKeyDown={(event) => {
              if (event && event.key === 'Enter') {
                handleEnter();
              }
            }}
          />       
          <Button 
            variant="contained"
            color="secondary"
            disabled={disabledPerchaButton}
            className={classes.perchaButton}
            onClick={() => {
              setPercha(percha + 1);
              setDisabledPerchabutton(true);
            }}
          >Finalizar Percha</Button> 
        </FormControl>
        
        <AppBar position="static">
          <Tabs 
            value={value} 
            onChange={handleTabChange}
          >
            <Tab label="Stock" id={0}/>
            <Tab label="Perchas" id={1}/>
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleTabChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Card>
              <CardContent className={classes.content}>
                <PerfectScrollbar>
                  <InventoryTable 
                    groupBarcodeItems={groupBarcodeItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    state={state}
                    onClickRow
                  />
                </PerfectScrollbar>
              </CardContent>
              <CardActions className={classes.actions}>
                <TablePagination
                  component="div"
                  count={state.items.length}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              </CardActions>
            </Card>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Card>
              <CardContent className={classes.content}>
                <PerchaTable 
                  groupPerchaItems={groupItemsByPercha}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  state={state}
                  onDeleteRow={handleDeletePerchaRow}
                />
              </CardContent>
            </Card>
          </TabPanel>
        </SwipeableViews>
        <InventoryFooter
          onExportData={() => 
            exportTableToCSV(`Toma Fisica ${state.bodega} ${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}.csv`)} 
          showExport={docID !== ''}
          onBackString={inventoryUrl}
        />
      </Container>
    </div>
  )
}

export default NewNativeStockCount;
