import { 
  SIGN_IN,
  SIGN_IN_ERROR,
  SIGN_IN_EXITO,
  SIGN_UP,
  SIGN_UP_EXITO,
  SIGN_UP_ERROR,
  SIGN_OUT
} from './../types/auth.types';
  
const initialState = {
  authenticated: false,
  loading: false,
  user: undefined,
  error: ''
}
  
export default function( state = initialState , action ){
  switch (action.type){
    case SIGN_IN:
      return {
        ...state,
        loading: true
      }
    case SIGN_IN_EXITO:
      return {
        ...state,
        authenticated: true,
        loading: false,
        user: action.payload,
        error: ''
      }
    case SIGN_IN_ERROR:
      return {
        ...state,
        loading: false,
        authenticated: false,
        user: undefined,
        error: action.payload
      }
    case SIGN_UP:
      return {
        ...state,
        loading: true
      }
    case SIGN_UP_EXITO:
      return {
        ...state,
        authenticated: true,
        user: action.payload,
        error: ''
      }
    case SIGN_UP_ERROR:
      return {
        ...state,
        loading: false,
        authenticated: false,
        user: undefined,
        error: action.payload
      }
    case SIGN_OUT:
      return {
        ...state,
        loading: false,
        authenticated: false,
        user: undefined,
        error: ''
      }
    default:
      return state
  }
}