import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  buttonBack:{
    marginRight: 50
  },
}));

const InventoryFooter = props => {
  const { className,showExport,onBackString,onExportData, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Link
          to={onBackString}
        >
          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonBack}
            startIcon={<ArrowBackIcon />}
          >
            Atrás
          </Button>
        </Link>
        <Button 
          disabled={!showExport}
          variant="contained" 
          color="primary"
          className={classes.exportButton}
          onClick={onExportData}
        >Exportar</Button>
      </div>
    </div>
  );
};

InventoryFooter.propTypes = {
  className: PropTypes.string,
  onBackString: PropTypes.func.isRequired,
  onExportData: PropTypes.func.isRequired,
  showExport: PropTypes.bool,
};

export default InventoryFooter;
