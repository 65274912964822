
export const entryRemittanceUrl = '/inventarios/ingreso_egreso';
export const newEntryRemittanceUrl = '/inventarios/ingreso_egreso/nuevo';

export const inventoryUrl = '/inventarios/toma-fisica'

export const tagUrl = '/inventarios/etiquetas';
export const newTagUrl = '/inventarios/etiquetas/nuevo';

export const inventoryDetails = (id) =>{
  return `${inventoryUrl}/${id}`
}

export const EntryRemittanceDetails = (id) =>{
  return `${entryRemittanceUrl}/${id}`
}