import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';

import { Transition } from './Transition';

const useStyles = makeStyles(theme => ({
  root: {
  },
  row: {
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '300px',
  },
  select: {
    marginBottom: '1em'
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 300,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const bodegasOptions = [
  { label: 'CCI', value: 'CCI' },
  { label: 'San Luis', value: 'SL'},
  { label: 'Condado', value: 'CN'},
  { label: 'Recreo 1', value: 'R1'},
  { label: 'Recreo 2', value: 'R2'}
];

const ciudadesOptions = [
  { label: 'Quito', value: 'UIO'},
  { label: 'Guayaquil', value: 'GYE'}
];

const Toolbar = props => {
  const { className, updateState, ...rest } = props;
  const today = new Date();
  const firstDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  );
  const [ state, setState ] = useState({
    locales: [],
    ciudad: [],
    fechaInicio: firstDayOfCurrentMonth.getTime(),
    fechaFinal: new Date().getTime(),
    open: false
  });

  const classes = useStyles();

  function handleChange(name, value) {
    setState({
      ...state,
      [name]: value
    });
  }

  function handleSelection(selectOption, action) {
    const { name } = action;
    const selections = state[name] ? state[name] : null;
    if (!selections) {
      return;
    }
    handleChange(name, selectOption || []);
    return;
  }

  return (
    <div
      {...rest}
      className={clsx(className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          onClick={handleChange.bind(this, 'open', !state.open)}
          variant="contained"
        >
          Filtrar
        </Button>
      </div>
      {state.open && <div className={classes.row}>
        <Dialog
          open={state.open}
          TransitionComponent={Transition}
          className={classes.root}
          keepMounted
          onClose={handleChange.bind(this, 'open', !state.open)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{'Filtros'}</DialogTitle>
          <DialogContent>
            <form 
              className={classes.form} 
              noValidate
            >
              <FormControl className={classes.formControl}>
                <div className={classes.select}>
                  <Select 
                    isMulti
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    onChange={handleSelection}
                    value={state.locales}
                    name="locales"
                    placeholder="Locales"
                    options={bodegasOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    menuPlacement="bottom"
                    menuPosition="fixed"
                  />
                </div>
                <div className={classes.select}>
                  <Select 
                    isMulti
                    onChange={handleSelection}
                    value={state.ciudad}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    name="ciudad"
                    placeholder="Ciudades"
                    options={ciudadesOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    menuPlacement="bottom"
                    menuPosition="fixed"
                  />
                </div>

                <DatePicker 
                  animateYearScrolling
                  format="DD-MM-YYYY"
                  label="Fecha Inicial"
                  value={state.fechaInicio}
                  onChange={handleChange.bind(this, 'fechaInicio')}
                />
                <DatePicker 
                  animateYearScrolling
                  format="DD-MM-YYYY"
                  label="Fecha Final"
                  value={state.fechaFinal}
                  onChange={handleChange.bind(this, 'fechaFinal')}
                />
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button 
              color="secondary"
              onClick={handleChange.bind(this, 'open', !state.open)} 
            >
              Cerrar
            </Button>
            <Button 
              color="primary"
              variant="contained"
              onClick={() => {
                updateState({
                  ...state,
                  open: !state.open
                });
                handleChange('open', !state.open);
              }}
            >
              Aplicar
            </Button>
          </DialogActions>
        </Dialog>
      </div>}
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  updateState: PropTypes.func
};

export default Toolbar;
