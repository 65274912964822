import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import uuid from 'uuid/v1';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  },
  circularProgress: {
    margin: theme.spacing(4),
    color: theme.palette.primary.main
  }
}));

const LatestProducts = props => {
  const { className, loading, products, ...rest } = props;

  const classes = useStyles();

  function liftProducts(allProducts) {
    if (!allProducts || !Array.isArray(allProducts)) {
      return null;
    }
    return allProducts.map((p) => ({
      id: uuid(),
      name: p.nombre,
      updatedAt: moment(p.recibido),
      proveedor: p.proveedor,
      imageUrl: p.image_url
    }))
  }

  const liftedProducts = liftProducts(products);


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        subtitle={loading ? '' : `${products.length} in total`}
        title="Últimos Productos"
      />
      <Divider />
      <CardContent className={classes.content}>
        {loading && <CircularProgress className={classes.circularProgress} />}
        {!loading &&
          <List>
            {liftedProducts.map((product, i) => (
              <ListItem
                divider={i < products.length - 1}
                key={product.id}
              >
                <ListItemAvatar>
                  <img
                    alt="Product"
                    className={classes.image}
                    src={product.imageUrl}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={product.name}
                  secondary={`Ingresado hace ${product.updatedAt.fromNow(true)}`}
                />
                <ListItemText
                  primary={'Proveedor'}
                  secondary={product.proveedor}
                />
                <IconButton
                  edge="end"
                  size="small"
                >
                  <MoreVertIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        }

      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          Ver Todos <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestProducts.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  products: PropTypes.array,
};

export default LatestProducts;
