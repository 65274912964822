import { makeStyles } from '@material-ui/styles';
import qs from 'query-string';
import React, { useState } from 'react';

import { useFetch } from '../../lib';
import { AnalyticsTabs, Toolbar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Ventas = () => {
  const classes = useStyles();
  const today = new Date();
  const firstDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  );
  const [ state, setState ] = useState({
    locales: [],
    ciudad: [],
    fechaInicio: firstDayOfCurrentMonth.getTime(),
    fechaFinal: new Date().getTime()
  });


  const [ resultado, loading ] = useFetch(`ventas?${qs.stringify(state)}`);
  return (
    <div className={classes.root}>
      <Toolbar updateState={setState}/>
      <AnalyticsTabs 
        title={'Ventas Totales'} 
        type={'VENTA_TOTAL'}
        query={state}
        loading={loading}
      />
      <AnalyticsTabs 
        title={'Por Proveedor'} 
        type={'VENTA_PROVEEDORES'} 
        query={state}
        loading={loading}

      />
      <AnalyticsTabs 
        title={'Por Supervisor'}
        type={'VENTA_SUPERVISOR'}
        query={state}
        loading={loading}
      />
      <AnalyticsTabs 
        title={'Por Vendedor'}
        type={'VENTA_VENDEDORES'}
        query={state}
        loading={loading}
      />
    </div>
  );
};

export default Ventas;
