import { Drawer } from '@material-ui/core';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { entryRemittanceUrl, inventoryUrl, tagUrl } from 'views/Inventory/urls';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { BrandProfile, SidebarNav } from './components';
import { providersUrl } from 'views/Providers/urls';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    // {
    //   title: 'Inicio',
    //   href: '/dashboard',
    //   icon: <DashboardIcon />
    // },
    // {
    //   title: 'Ventas',
    //   href: '/ventas',
    //   icon: <MoneyIcon />,
    //   children: [{
    //     title: 'Por Local',
    //     href: '/ventas/local',
    
    //   }, {
    //     title: 'Por Proveedor',
    //     href: '/ventas/proveedor'
    //   }]
    // },
    {
      title: 'Inventarios',
      href: '/inventarios',
      icon: <ShoppingBasketIcon />,
      children: [
        {
          title: 'Toma Física',
          href: inventoryUrl
        },
        {
          title : 'Ingreso/Egreso',
          href: entryRemittanceUrl
        },
        {
          title : 'Etiquetas',
          href: tagUrl
        }
      ]
    },
    {
      title: 'Compras',
      href: providersUrl,
      icon: <ShoppingCartIcon />,
      children: [
        {
          title: 'Proveedores',
          href: providersUrl
        }
      ]
    },
    // {
    //   title: 'Facturas',
    //   href: '/facturas',
    //   icon: <AssesmentIcon/>
    // },
    // {
    //   title: 'Clientes',
    //   href: '/clientes',
    //   icon: <UsersIcon/>
    // },
    // {
    //   title: 'Typography',
    //   href: '/typography',
    //   icon: <TextFieldsIcon />
    // },
    // {
    //   title: 'Cuenta',
    //   href: '/cuenta',
    //   icon: <AccountBoxIcon />
    // },
    // {
    //   title: 'Ajustes',
    //   href: '/ajustes',
    //   icon: <SettingsIcon />
    // }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <BrandProfile />
        {/* <Divider className={classes.divider} /> */}
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
