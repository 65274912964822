/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import EntryRemittanceDetails from './TagDetails';
import { withRouter } from 'react-router';
import TagToolbar from '../components/Tag/TagToolbar';
import TagTable from '../components/Tag/TagTable_list';
import { getEntries, getProductsFirestore , getProductVariants, getVariants, loadDataToFirestore} from './../utils/firestoreFunctions';
import { inventarioTomaFisica } from 'firebase/collections';
import { inventoryTypeEnum } from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const TagList = ({
  history
}) => {

  const classes = useStyles()
  const [rowsPerPage,setRowsPerPage] = React.useState(10);
  const [totalItems,setTotalItems] = React.useState(0);
  const [page,setPage] = useState(0);
  const [products,setProducts] = useState([]);
  const [variants,setVariants] = useState([]);
  const [showRefresh,setShowRefresh] = useState(false);
  const [entries,setInventoryEntries] = React.useState([]);
  const [messageRefresh,setMessageRefresh] = React.useState('')

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const getProductsFromDb = async () =>{
    const idLastProduct = products.length !== 0 ? products[products.length-1].id :'';
    const { data } = await getProductsFirestore(rowsPerPage,page,idLastProduct);
    setProducts(data.data);
    setTotalItems(data.totalItems);
  }

  const refreshDataFirestore = async () =>{
    const data = await loadDataToFirestore();
    if(data.status === 200){
      setShowRefresh(false);
      getProductsFromDb();
      setMessageRefresh('Se han actualizado los productos');
    }else{
      setShowRefresh(true)
    }
  }

  React.useEffect(()=>{
    setTimeout(()=>{
      setMessageRefresh(' ')
    },5000)
  },[messageRefresh])

  const getVariantsFromDb = async () =>{
    const { data } = await getVariants();
    setVariants(data.data)
  }

  const searchProducts = async( productInput )=>{
    const { data } = await getProductVariants(productInput);
    const productAuxarr = [];
    if(data.variants.length !== 0 || data.data.length !== 0 ){
      productAuxarr.push(data.data[0]);
      data.variants.map( variant =>{
        productAuxarr.push(variant);
      })
    }
    if(productAuxarr.length !== 0){
      setProducts(productAuxarr);
      setTotalItems(productAuxarr.length)
    }else{
      setTimeout(()=>{
        setMessageRefresh('');
      },8000)
      setMessageRefresh('El producto no se encuentra');
      getProductsFromDb()
    }
  }

  const getEntriesFromDb = async () =>{
    const { data } = await getEntries(
      rowsPerPage,
      {
        direction: 'desc',
        field: 'lastSaved'
      },
      page,
      inventarioTomaFisica,
      {
        field: 'type',
        operator: '==',
        value: inventoryTypeEnum.ETIQUETA
      }
    );
    setInventoryEntries(data);
  }

  React.useEffect(()=>{
    getEntriesFromDb();
    getVariantsFromDb();
    getProductsFromDb();
  },[rowsPerPage,page]);

  return ( 
    <div className={classes.root}>
      <TagToolbar
        searchProduct={searchProducts}
        refreshProducts={() =>{
          refreshDataFirestore();
        }}
        setShowRefresh={setShowRefresh}
        showRefresh={showRefresh}
        messageRefresh={messageRefresh}
      />
      <div className={classes.content}>
        <TagTable
          entries={entries}
          products={products}
          variants={variants}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalItems={totalItems}
          handlePageChange={handlePageChange}
          page={page}
          onRowClick={(id) => () => history.push(EntryRemittanceDetails(id))}
        />
      </div>
    </div> 
  );
}
 
export default withRouter(TagList);