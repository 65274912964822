import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const InventoryTable = ({groupBarcodeItems,state,page,rowsPerPage}) => {
  return ( 
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Código de Barras</TableCell>
            <TableCell>Cantidad</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupBarcodeItems(state.items).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(entry => (
            <TableRow
              hover
              key={entry.id}
            >
              <TableCell>
                {entry.barcode}
              </TableCell>
              <TableCell>{entry.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

InventoryTable.propTypes = {
  groupBarcodeItems : PropTypes.func.isRequired,
  page: PropTypes.number.isRequired.length,
  rowsPerPage: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
}
 
export default InventoryTable;
