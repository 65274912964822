import { Avatar, Card, CardContent, CircularProgress, Grid, Typography } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ProductIcon from '@material-ui/icons/ShoppingCartOutlined';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  progress: {
    margin: theme.spacing(4),
    color: theme.palette.primary.main
  }
}));

const TotalItems = props => {
  const { className, items, loading, ...rest } = props;

  const classes = useStyles();

  const prettyItems = items ? numeral(items).format('0,0') : null;
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              ITEMS VENDIDOS
            </Typography>
            {loading && <CircularProgress className={classes.progress} />}
            {!loading && <Typography variant="h3">{prettyItems}</Typography>}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <ProductIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          <ArrowUpwardIcon className={classes.differenceIcon} />
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            16%
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            Desde el mes pasado
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

TotalItems.propTypes = {
  className: PropTypes.string,
  items: PropTypes.number,
  loading: PropTypes.bool.isRequired
};

export default TotalItems;
