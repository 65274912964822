/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import EntryRemittanceToolbar from  './../components/EntryRemittance/EntryRemittanceToolbar';
import EntryRemittanceTable from  './../components/EntryRemittance/EntryRemittanceTable';
import { useState } from 'react';
import {EntryRemittanceDetails} from './../urls';
import { withRouter } from 'react-router';
import { inventarioTomaFisica } from 'firebase/collections';
import { inventoryTypeEnum } from 'common/constants';
import { getEntries, getTotalItems } from '../utils/firestoreFunctions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const EntryRemittanceList = ({
  history
}) => {

  const classes = useStyles()
  const [rowsPerPage,setRowsPerPage] = React.useState(10);
  const [totalItems,setTotalItems] = React.useState(0)
  const [inventoryEntries,setInventoryEntries] = useState([]);
  const [page,setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const getEntriesFromDb = async () =>{
    const { data } = await getEntries(
      rowsPerPage,
      {
        direction: 'desc',
        field: 'lastSaved'
      },
      page,
      inventarioTomaFisica,
      {
        field: 'type',
        operator: 'in',
        value: [inventoryTypeEnum.INGRESO,inventoryTypeEnum.EGRESO]
      }
    );
    setInventoryEntries(data);
  }

  const getTotalItemsFromDb = async () =>{
    const { totalItems2 } = await getTotalItems(
      inventarioTomaFisica,
      {
        field: 'type',
        operator: 'in',
        value: [inventoryTypeEnum.INGRESO,inventoryTypeEnum.EGRESO]
      }
    );
    setTotalItems(totalItems2);
  }

  React.useEffect(()=>{
    getEntriesFromDb();
    getTotalItemsFromDb();
  },[rowsPerPage,page]);

  return ( 
    <div className={classes.root}>
      <EntryRemittanceToolbar />
      <div className={classes.content}>
        <EntryRemittanceTable
          entries={inventoryEntries}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalItems={totalItems}
          handlePageChange={handlePageChange}
          page={page}
          onRowClick={(id) => () => history.push(EntryRemittanceDetails(id))}
        />
      </div>
    </div> 
  );
}
 
export default withRouter(EntryRemittanceList);
