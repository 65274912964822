/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  AppBar,
  Card,
  CardActions,
  CardContent,
  Select,
  Tab,
  TablePagination,
  Tabs,
  TextField,
  CircularProgress
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { saveInventoryAction } from '../../../actions/Inventory.actions';
import {useDispatch} from 'react-redux';
import { inventoryStatusEnum, inventoryTypeEnum } from '../../../common/constants';
import { InventoryFooter } from '../components/Inventory'
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { db } from '../../../firebase';
import { inventarioTomaFisica } from '../../../firebase/collections';
import SearchInput from '../../../components/SearchInput';
import { tagUrl } from '../urls';
import TagTable from '../components/Tag/TagTable';
import { getProductVariants, getVariants, loadDataToFirestore } from '../utils/firestoreFunctions';
import TabPanel from 'views/Ventas/components/AnalyticsTabs/TabPanel';
import RefreshIcon from '@material-ui/icons/Refresh';


const today = new Date();

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  content: {
    marginTop: theme.spacing(2)
  },
  titleContainer: {
    paddingBottom: theme.spacing(5)
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  formItem: {
    marginBottom: '20px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlLarge: {
    margin: theme.spacing(2),
    width: '100%',
    marginLeft: 0
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  searchInput: {
    width: '100%',
    display: 'block',
    flexBasis: '42px'
  }
}));

const NewtagEntry = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [successSaved,setSuccessSaved] = React.useState(false);
  const [variants,setVariants] = React.useState([])
  const [errorMsg,setErrorMsg] = React.useState('');
  const [docID,setDocID] = React.useState('');
  const [canSave,setCanSave] = React.useState(false);
  const [state, setState ] = React.useState({
    fecha: `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`,
    bodega: '',
    responsable: '',
    items: {
      products: [],
      boxes: []
    },
    totalItems: 0,
    ingresoEgreso: '',
    lastSaved: 0,
    status: inventoryStatusEnum.IN_PROGESS,
    type: inventoryTypeEnum.ETIQUETA,
    quantity: 0
  });
  const [diableFields,setDisableFields] = React.useState(false);
  const [query,setQuery] = React.useState('')
  const [showRefresh,setShowRefresh] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [messageRefresh,setMessageRefresh] = React.useState('')
  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };
  const handleEnter = () => {

    setCanSave(true);

    setState({
      ...state,
      items: {
        products: [...state.items.products],
        boxes: [...state.items.boxes]
      },
      totalItems: state.totalItems + 1,
    });
    setQuery('')
    return;
  }

  const refreshDataFirestore = async () =>{
    const data = await loadDataToFirestore();
    if(data.status === 200){
      setShowRefresh(false);
      setMessageRefresh('Se han actualizado los productos');
    }else{
      setShowRefresh(true)
    }
  }

  React.useEffect(()=>{
    setTimeout(()=>{
      setMessageRefresh(' ')
    },5000)
  },[messageRefresh])

  React.useEffect(()=>{
    setDisableFields(state.status === inventoryStatusEnum.COMPLETED)
  },[state.status]);

  const getVariantsFromDb = async () =>{
    const { data } = await getVariants();
    setVariants(data.data)
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const validateData = () =>{
    setErrorMsg('');
    let err = false;
    if(!state.bodega){
      setErrorMsg('Por Favor llene Todos los Datos');
      err = true;
    }
    if(!state.responsable){
      setErrorMsg('Por Favor llene Todos los Datos');
      err = true;
    }
    if(!err){
      setErrorMsg('');
      setCanSave(false);
      return true;
    }else{
      return false;
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const searchProducts = async( productInput )=>{
    if(productInput){
      const { data } = await getProductVariants(productInput);
      const newProductItems = [...state.items.products];
      const newBoxesItems = [...state.items.boxes];
      data.variants.map( variant => {
        newProductItems.push({
          product: variant,
          quantity: 0
        });
        newBoxesItems.push({
          product: variant,
          quantity: 0
        });
      })
      setState({...state, items: {
        products: newProductItems,
        boxes: newBoxesItems
      }});
    }
  }

  React.useEffect(()=>{
    searchProducts(query);
  },[query, searchProducts])

  React.useEffect(()=>{
    getVariantsFromDb();
  },[rowsPerPage,page]);

  const downloadCSV = (csv, filename) => {
    var csvFile;
    var downloadLink;

    csvFile = new Blob([csv], {type: 'text/csv'});

    downloadLink = document.createElement('a');

    downloadLink.download = filename;

    downloadLink.href = window.URL.createObjectURL(csvFile);

    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);

    downloadLink.click();
  }

  const exportTableToCSV = (filename) => {
    var csv = [];
    var rows = document.querySelectorAll('table tr');
    
    for (var i = 0; i < rows.length; i++) {
      var row = [], cols = rows[i].querySelectorAll('td, th');
      
      for (var j = 0; j < cols.length; j++){
        let input = cols[j].querySelectorAll('input')[0];
        if(input){
          row.push(input.value);
        }else{
          row.push(cols[j].innerText);
        }
      }
      csv.push(row.join(','));
    }

    downloadCSV(csv.join('\n'), filename);

    updateDocument(true);

  }

  React.useEffect(()=>{
    setState({
      ...state,
      totalItems: state.items.products.length
    })
  },[state, state.items.products])

  const saveDocument = () =>{
    db.collection(inventarioTomaFisica).add({
      ...state,
      lastSaved: Date.now()
    }).then((docRef)=>{
      setTimeout(()=>{
        setSuccessSaved(false);
      },3000);
      setDocID(docRef.id)
      setSuccessSaved(true);
      setCanSave(false);
    });
    dispatch(saveInventoryAction({
      ...state,
      lastSaved: Date.now()
    }));
  }

  const updateDocument = async (exportData) =>{
    const document = await db.collection(inventarioTomaFisica).doc(docID);
    let stateUpdated = {};

    if(exportData){
      stateUpdated = {
        ...state,
        status: inventoryStatusEnum.COMPLETED ,
        lastSaved: Date.now()
      }
      setDisableFields(true);
    }else{
      stateUpdated = {
        ...state,
        lastSaved: Date.now()
      }
    }

    document.update(stateUpdated).then(()=>{
      setTimeout(()=>{
        setSuccessSaved(false);
      },4000);
      setSuccessSaved(true);
      setCanSave(false);
    });
    dispatch(saveInventoryAction({
      ...state,
      lastSaved: Date.now()
    }))
  }

  const changeQuantity = (id,quantity,type)=>{
    if(type === 'product'){
      const productIndex = state.items.products.findIndex( item => item.product.id === id );
      const auxArr = [...state.items.products]
      auxArr[productIndex] = {
        product: auxArr[productIndex].product,
        quantity 
      };
      setState({
        ...state,
        items: {
          products: auxArr,
          boxes: state.items.boxes
        }
      })
    }else{
      const boxIndex = state.items.boxes.findIndex( item => item.product.id === id );
      const auxArr = [...state.items.boxes]
      auxArr[boxIndex] = {
        product: auxArr[boxIndex].product,
        quantity 
      };
      setState({
        ...state,
        items: {
          products: state.items.products,
          boxes: auxArr
        }
      })
    }
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Typography 
          variant="h2" 
          align="center"
          className={classes.titleContainer}
        >
          Impresión de Etiquetas
        </Typography>
        <Typography 
          variant="h5" 
          align="center"
          className={classes.titleContainer}
        >
          {`Items Totales: ${state.totalItems}`}
        </Typography>
        <div className={classes.mt5}>
          { 
            showRefresh?
              <>
                <CircularProgress color="secondary" />
              </> 
              : 
              <Typography
                variant="body1"
                color="secondary"
              >{messageRefresh}</Typography>
          }
        </div>
        {
          successSaved ? <Alert severity="success">Cambios Guardados</Alert> : null
        }
        {
          errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null
        }
        <FormControl className={classes.formControl}>
          <div className={classes.row}>
            <TextField
              value={state.fecha} 
              label="Fecha" 
              disabled
            />
            <IconButton
              aria-label="save" 
              disabled={diableFields || ((state.items.products.length === 0 || state.items.boxes.length === 0 ) || !canSave)}
              color="primary"
              onClick={async () =>{
                setCanSave(false);
                if(validateData()){
                  if(!docID){
                    saveDocument();
                  }else{
                    updateDocument();
                  }
                  setState({
                    ...state,
                    lastSaved: Date.now()
                  });
                }
              }}
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                refreshDataFirestore();
                setShowRefresh(true);
              }}
            >
              <RefreshIcon />
            </IconButton>
          </div>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="bodega-native-simple">Bodega</InputLabel>
          <Select
            native
            disabled={diableFields}
            value={state.bodega}
            onChange={handleChange}
            inputProps={{
              name: 'bodega',
              id: 'bodega-native',
            }}
          >
            <option 
              aria-label="None"
              value="" 
            />
            <option value={'PORTAL SHOPPING'}>Portal Shopping</option>
            <option value={'Bodega Matriz'}>Bodega Matriz</option>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="bodega-native-simple">Responsable</InputLabel>
          <Select
            native
            disabled={diableFields}
            value={state.responsable}
            onChange={handleChange}
            inputProps={{
              name: 'responsable',
              id: 'responsable-native',
            }}
          >
            <option 
              aria-label="None"
              value=""
            />
            <option value={'SUSANA SOLANO'}>Susana Solano</option>
            <option value={'NIXON ALVARADO'}>Nixon Alvarado</option>
          </Select>
        </FormControl>
        <FormControl className={classes.formControlLarge}>
          <SearchInput
            name="query"
            disabled={diableFields}
            className={classes.searchInput}
            value={query}
            onChange={e => setQuery(e.target.value)}
            placeholder={'Código de Barras'}
            onKeyDown={(event) => {
              if (event && event.key === 'Enter') {
                handleEnter();
              }
            }}
          />        
        </FormControl>
        <Card>
          <CardContent className={classes.content}>
            <AppBar position="static">
              <Tabs 
                value={value}
                onChange={handleTabChange}
                aria-label="simple tabs example"
              >
                <Tab 
                  label="Zapato" 
                  {...a11yProps(0)}
                />
                <Tab
                  label="Caja"
                  {...a11yProps(1)}
                />
              </Tabs>
            </AppBar>
            <TabPanel 
              value={value}
              index={0}
            >
              <PerfectScrollbar>
                <TagTable
                  tabIndex={value}
                  variants={variants}
                  changeQuantity={changeQuantity}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  state={state}
                />
              </PerfectScrollbar>
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
            >
              <PerfectScrollbar>
                <TagTable
                  tabIndex={value}
                  variants={variants}
                  changeQuantity={changeQuantity}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  state={state}
                />
              </PerfectScrollbar>
            </TabPanel>
          </CardContent>
          <CardActions className={classes.actions}>
            <TablePagination
              component="div"
              count={state.items.products.length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </CardActions>
        </Card>
        <InventoryFooter
          onExportData={() => exportTableToCSV(`${state.type[0].toUpperCase()}${state.type.slice(1).toLowerCase()} ${state.bodega} ${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}.csv`)}
          showExport={docID !== ''}
          onBackString={tagUrl}
        />
      </Container>
    </div>
  )
}

export default NewtagEntry;
