import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

export const firebaseConfig = {
  apiKey: 'AIzaSyA2CXNHrdLvyTv5cz1xNE2UUu0IPU1AN5Y',
  authDomain: 'epsilon-technology.firebaseapp.com',
  databaseURL: 'https://epsilon-technology.firebaseio.com',
  projectId: 'epsilon-technology',
  storageBucket: 'epsilon-technology.appspot.com',
  messagingSenderId: '1085090484057',
  appId: '1:1085090484057:web:0a7062bb6c65fd8e924648',
  measurementId: 'G-Y0SH65VRW2'
};
  // Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);
export const db = fb.firestore();
export const auth = fb.auth();
