import {
  SIGN_IN,
  SIGN_IN_EXITO,
  SIGN_IN_ERROR,
  SIGN_UP,
  SIGN_UP_EXITO,
  SIGN_UP_ERROR,
  SIGN_OUT
} from '../types/auth.types';
import { auth } from './../firebase'
import { singnInUrl } from './../views/SignIn/urls';

export const singInAction = ( user , history) =>{
  return async (dispatch)=>{
    try {
      dispatch( signIn() );
      const userSigned = await auth.signInWithEmailAndPassword(user.email,user.password); 
      if(userSigned){
        dispatch( signInSuccess(userSigned) );
        history.push('/inventarios/toma-fisica');
      }
    } catch (error) {
      dispatch(signInError(error.code));
    }
  }
}

export const signIn = () => ({
  type: SIGN_IN
});

export const signInSuccess = user => ({
  type: SIGN_IN_EXITO,
  payload: user
})

export const signInError = (errCode) => ({
  type: SIGN_IN_ERROR,
  payload: errCode
})

export const singUpAction = ( user , history) =>{
  return async (dispatch)=>{
    try {
      dispatch( signUp() );
      const userCreated = await auth.createUserWithEmailAndPassword(user.email, user.password);
      if(userCreated){
        dispatch( signUpSuccess({
          userCreated
        }));
        history.push('/inventarios/toma-fisica');
      }
    } catch (error) {
      dispatch(signUpError(error.code));
    }
  }
}

export const signUp = () => ({
  type: SIGN_UP
});

export const signUpSuccess = user => ({
  type: SIGN_UP_EXITO,
  payload: user
})

export const signUpError = (error) => ({
  type: SIGN_UP_ERROR,
  payload: error
})

export const signOutAction = (history) =>{
  return async (dispatch)=>{
    try {
      dispatch(signOut());
      history.push(singnInUrl);
    } catch (error) {
      dispatch(signUpError());
    }
  }
}

export const signOut = () => ({
  type: SIGN_OUT
})
