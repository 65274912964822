import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import uuid from 'uuid/v1';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  circularProgress: {
    margin: theme.spacing(4),
    color: theme.palette.primary.main
  }
}));


const LatestOrders = props => {
  const { className, loading, orders, ...rest } = props;

  const classes = useStyles();


  const liftedOrders = liftOrders(orders);

  function liftOrders(allOrders) {
    if (!allOrders || !Array.isArray(allOrders)) {
      return null;
    }
    return allOrders.map((o) => ({
      id: uuid(),
      ref: o.numero_factura,
      cliente: o.cliente,
      createdAt: o.fecha,
      local: o.local,
      valor: `$ ${numeral(o.valor).format('0,0')}`,
      forma_de_pago: o.forma_de_pago
    }));
  }
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Últimas Compras"
      />
      <Divider />
      <CardContent className={classes.content}>
        {loading && <CircularProgress className={classes.circularProgress} />}
        {!loading &&
                <PerfectScrollbar>
                  <div className={classes.inner}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nro Factura</TableCell>
                          <TableCell>Cliente</TableCell>
                          <TableCell sortDirection="desc">
                            <Tooltip
                              enterDelay={300}
                              title="Sort"
                            >
                              <TableSortLabel
                                active
                                direction="desc"
                              >
                                Fecha
                              </TableSortLabel>
                            </Tooltip>
                          </TableCell>
                          <TableCell>Local</TableCell>
                          <TableCell>Valor</TableCell>
                          <TableCell>Forma de Pago</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {liftedOrders.map(order => (
                          <TableRow
                            hover
                            key={order.id}
                          >
                            <TableCell>{order.ref}</TableCell>
                            <TableCell>{order.cliente}</TableCell>
                            <TableCell>
                              {moment(order.createdAt).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell>
                              {order.local}
                            </TableCell>
                            <TableCell>
                              {order.valor}
                            </TableCell>
                            <TableCell>
                              {order.forma_de_pago}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </PerfectScrollbar>
        }
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          View all <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  orders: PropTypes.object
};

export default LatestOrders;
