import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import PieChartIcon from '@material-ui/icons/PieChart';
import TocOutlinedIcon from '@material-ui/icons/TocOutlined';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BarGraph, DataTable, PieChart } from '../../../../components';
import TabPanel from './TabPanel.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: '1.2em',
  },
  graphContainer: {
    width: '100%',
    marginTop: theme.spacing(1),
    height: 300,
    position: 'relative'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginLeft: '1.2em',
    marginTop: '1em'
  },
  progress: {
    margin: theme.spacing(10),
    color: theme.palette.primary.main
  }
}));

const chatData = {
  'ciudad': 'Quito',
  'labels': ['CCI', 'Bosque', 'San Luis', 'Condado', 'Recreo', 'Quicentro Sur'],
  'data': [0.4, 0.10, 0.10, 0.10, 0.1, 0.1]
}
const data = {
  'labels': ['2019-09-10', '2019-09-11', '2019-09-12', '2019-09-13', '2019-09-14', '2019-09-15', '2019-09-16'],
  'data': {
    'actual': [100, 200, 300, 400, 500, 600, 700],
    'cuota': [200, 300 , 400, 500, 600, 700, 800]
  }
};

const DATA_HEADERS = {
  'VENTA_TOTAL': [
    { id: 'local', numeric: false, disablePadding: true, label: 'Local' },
    { id: 'nro_items', numeric: true, disablePadding: false, label: 'Numero de Items' },
    { id: 'nro_pares', numeric: true, disablePadding: false, label: 'Numero de Pares' },
    { id: 'fecha', numeric: true, disablePadding: false, label: 'Fecha' },
    { id: 'total', numeric: true, disablePadding: false, label: 'Venta Total' },  
  ],
  'VENTA_PROVEEDORES': [
    { id: 'local', numeric: false, disablePadding: true, label: 'Local' },
    { id: 'stock_vendido', numeric: true, disablePadding: false, label: 'Stock Vendido' },
    { id: 'stock', numeric: true, disablePadding: false, label: 'Stock Disponible' },
    { id: 'valor_vendido', numeric: true, disablePadding: false, label: 'Total Vendido Dia ($)' },
    { id: 'fecha', numeric: true, disablePadding: false, label: 'Fecha' },
    { id: 'total', numeric: true, disablePadding: false, label: 'Venta Total' },  
    { id: 'valor_disponible', numeric: true, disablePadding: false, label: 'Total Disponible($)' },
  ],
  'VENTA_SUPERVISOR': [
    { id: 'local', numeric: false, disablePadding: true, label: 'Local' },
    { id: 'nro_items', numeric: true, disablePadding: false, label: 'Numero de Items' },
    { id: 'nro_pares', numeric: true, disablePadding: false, label: 'Numero de Pares' },
    { id: 'fecha', numeric: true, disablePadding: false, label: 'Fecha' },
    { id: 'cuota', numeric: true, disablePadding: false, label: 'Cuota' },
    { id: 'porcentaje_cuota', numeric: true, disablePadding: false, label: 'Porcentaje Cuota (%)' },
    { id: 'total', numeric: true, disablePadding: false, label: 'Venta Total' },    
  ],
  'VENTA_VENDEDORES': [
    { id: 'nombre_completo', numeric: true, disablePadding: false, label: 'Nombre Completo' },
    { id: 'local', numeric: true, disablePadding: false, label: 'Local' },
    { id: 'fecha', numeric: true, disablePadding: false, label: 'Fecha' },
    { id: 'nro_items', numeric: true, disablePadding: false, label: 'Numero de Items' },
    { id: 'nro_pares', numeric: true, disablePadding: false, label: 'Numero de Pares' },
    { id: 'total', numeric: true, disablePadding: false, label: 'Venta Total' },    
  ]
}

const AnalyticsTabs = (props) => {
  const classes = useStyles();
  const [selectedTab, setTab] = useState(0);
  function handleChange(event, newValue) {
    setTab(newValue);
  }

  return(
    <div className={classes.root}>
      <Paper elevation={5}>
        {props.loading && <CircularProgress className={classes.progress}/>}
        {!props.loading && 
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab icon={<TocOutlinedIcon />}/>
          <Tab icon={<BarChartOutlinedIcon />}/>
          <Tab icon={<PieChartIcon />}/>
        </Tabs> }
        {!props.loading &&
          <div className={classes.title}>
            <Typography variant="h2">
              {props.title}
            </Typography>
          </div>  
        }
      
        {!props.loading &&
        <TabPanel
          value={selectedTab}
          index={0}
        >
          <DataTable 
            headCells={DATA_HEADERS[props.type]}
          />
        </TabPanel>
        }
        {!props.loading &&
        <TabPanel
          value={selectedTab}
          index={1}
        >
          <Card>
            <CardContent>
              <div className={classes.graphContainer}>
                <BarGraph
                  data={data}
                />
              </div>
            </CardContent>
          </Card>
        </TabPanel>
        }
        {!props.loading &&
        <TabPanel
          value={selectedTab}
          index={2}
        >
          <Card>
            <CardContent>
              <div className={classes.graphContainer}>
                <PieChart
                  data={chatData}
                />
              </div>
            </CardContent>
          </Card>
        </TabPanel>
        }
      </Paper>
    </div>
  )

}


AnalyticsTabs.propTypes = {
  loading: PropTypes.bool,
  query: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
}
export default AnalyticsTabs;