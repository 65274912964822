import { 
  GUARDAR_TOMA_FISICA
} from './../types/inventory.types';

const today = new Date();

const initialState = {
  fecha: `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`,
  bodega: '',
  email: '',
  responsable: '',
  query: '',
  items: [],
  totalItems: 0,
  lastSaved: 0
}

export default function( state = initialState , action ){
  switch (action.type){
    case GUARDAR_TOMA_FISICA:
      return action.payload;
    default:
      return state
  }
}