import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React from 'react';

export const PerchaTable = ({ groupPerchaItems, onDeleteRow, state, page, rowsPerPage }) => {
  return ( 
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Percha</TableCell>
            <TableCell>Cantidad</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {groupPerchaItems(state.items).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(entry => (
            <TableRow
              hover
              key={entry.percha}
            >
              <TableCell> {entry.percha} </TableCell>
              <TableCell>{entry.count}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={ ()=> {
                    onDeleteRow(entry.percha);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}


PerchaTable.propTypes = {
  groupPerchaItems: PropTypes.func.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired.length,
  rowsPerPage: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
};

export default PerchaTable;
