import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#a7561f',
    main: '#f8ad3d',
    light: '#f29657'
  },
  secondary: {
    contrastText: white,
    dark: '#ad792a',
    main: '#1c2240',
    light: '#f9bd63'
  },
  success: {
    contrastText: white,
    dark: '#388e3c',
    main: '#4caf50',
    light: '#81c784'
  },
  info: {
    contrastText: white,
    dark: '#1976d2',
    main: '#2196f3',
    light: '#64b5f6'
  },
  warning: {
    contrastText: white,
    dark: '#f57c00',
    main: '#ff9800',
    light: '#ffb74d'
  },
  error: {
    contrastText: white,
    dark: '#d32f2f',
    main: '#f44336',
    light: '#e57373'
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
};
