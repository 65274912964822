/* eslint-disable react/prop-types */
import { makeStyles } from '@material-ui/styles';
import { inventoryTypeEnum } from 'common/constants';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { inventarioTomaFisica } from '../../firebase/collections';
import { InventoryTable, InventoryToolbar } from './components/Inventory';
import { inventoryDetails } from './urls';
import { getEntries } from './utils/firestoreFunctions';
import { groupBarcodeItems } from './utils/inventory';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const InventoryList = ({
  history
}) => {
  const classes = useStyles();
  const [rowsPerPage,setRowsPerPage] = React.useState(10);
  const [totalItems,setTotalItems] = React.useState(0)
  const [ allInventoryEntries, setAllInventoryEntries ] = useState([]);
  const [page, setPage] = useState(0);
  const [ isMasterCheckbox, setMasterCheckbox ] = React.useState(false);
  const [ isFilterByDate, setFilterByDate ] = React.useState(false);
  const [ selectedRows, setSelectedRows ] = React.useState([]);
  const [ startDate, selectStartDate ] = React.useState(new Date());
  const [ endDate, selectEndDate ] = React.useState(new Date());
  const [ isDescending, setSortDirection ] = React.useState(true);


  const startIndex = rowsPerPage * page > 0 ?(rowsPerPage * page)  - 1: rowsPerPage * page;
  const endIndex = startIndex + rowsPerPage;
  const inventoryEntries = allInventoryEntries && allInventoryEntries.length > (startIndex + 1) ? allInventoryEntries.slice(startIndex, endIndex) : allInventoryEntries;
  const selectMasterCheckbox = () => {
    const allIndices = inventoryEntries.map((entry, index) => index);
    if (isMasterCheckbox) {
      setSelectedRows([]);
    } else {
      setSelectedRows(allIndices);
    }
    setMasterCheckbox(!isMasterCheckbox)
  }

  const getTotalItemsFromDb = async (extraConditions) => {
    const baseCondition = {
      field: 'type',
      operator: '==',
      value: inventoryTypeEnum.TOMA_FISICA
    };
    const conditions = Array.isArray(extraConditions) ? [baseCondition, ...extraConditions] : [baseCondition];
    const direction = isDescending ? 'desc' : 'asc';
    const { data, size } = await getEntries(
      rowsPerPage,
      {
        field: 'lastSaved',
        direction: direction
      },
      page,
      inventarioTomaFisica,
      conditions
    );
    if (size && !isNaN(size)) {
      setTotalItems(size);
    }
    if (data && Array.isArray(data)) {
      setAllInventoryEntries(data);
    }
  }


  React.useEffect(()=>{
    getTotalItemsFromDb();
  }, []);

  React.useEffect(() => {
    // If filter by date is enabled, we need to apply the same filters
    const extraConditions = [];

    if (isFilterByDate) {
      const startDateEpoch = moment(startDate.valueOf()).startOf('day').valueOf();
      const endDateEpoch = moment(endDate.valueOf()).endOf('day').valueOf();
      extraConditions.push({
        field: 'lastSaved',
        operator: '>=',
        value: startDateEpoch
      });
      extraConditions.push({
        field: 'lastSaved',
        operator: '<=',
        value: endDateEpoch
      });
    }
    getTotalItemsFromDb(extraConditions);
  }, [page, isDescending]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleExportClick = () => {
    const selectedRowsIx = new Set([...selectedRows]);
    const allItems = allInventoryEntries
      .filter((item, ix) => selectedRowsIx.has(ix))
      .map((docItem) => {
        return docItem.data.items;
      })

    const selectedItems = [].concat.apply([], allItems);
    const groupedItems = groupBarcodeItems(selectedItems);
    const csv = [['Producto (Código)', 'Cantidad']];
    for (const item of groupedItems) {
      const newRow = [item.barcode, item.count];
      csv.push(newRow);
    }

    const csvContents = csv.join('\n')
    var csvFile;
    var downloadLink;

    csvFile = new Blob([csvContents], {type: 'text/csv'});

    downloadLink = document.createElement('a');

    downloadLink.download = 'Exportacion Toma Fisica.csv';

    downloadLink.href = window.URL.createObjectURL(csvFile);

    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);

    downloadLink.click();
  }

  const handleUpdateSortDirection = () => {
    setSortDirection(!isDescending);
    return;
  }
  const handleSearchByDateRange = () => {
    const startDateEpoch = moment(startDate.valueOf()).startOf('day').valueOf();
    const endDateEpoch = moment(endDate.valueOf()).endOf('day').valueOf();
    if (startDateEpoch && endDateEpoch) {
      const extraConditions = [
        {
          field: 'lastSaved',
          operator: '>=',
          value: startDateEpoch
        },
        {
          field: 'lastSaved',
          operator: '<=',
          value: endDateEpoch
        }
      ]
      getTotalItemsFromDb(extraConditions);
    }
    setFilterByDate(true);
  };
  return (
    <div className={classes.root}>
      <InventoryToolbar 
        selectedRows={selectedRows}
        onDateSearch={handleSearchByDateRange}
        onExportClick={handleExportClick}
        startDate={startDate}
        endDate={endDate}
        onSelectEndDate={selectEndDate}
        onSelectStartDate={selectStartDate}
      />
      <div className={classes.content}>
        <InventoryTable
          isDescending={isDescending}
          entries={inventoryEntries}
          rowsPerPage={rowsPerPage}
          onUpdateSort={handleUpdateSortDirection}
          setRowsPerPage={setRowsPerPage}
          totalItems={totalItems}
          handlePageChange={handlePageChange}
          page={page}
          onClickRow={(id) => {
            history.push(inventoryDetails(id));
          }}
          isMasterCheckbox={isMasterCheckbox}
          setMasterCheckbox={selectMasterCheckbox}
          selectedRows={selectedRows}
          selectRowIndex={(index) => {
            const foundIndex = selectedRows.findIndex((newIndex) => newIndex === index);
            if (foundIndex === -1) {
              const newRows = [...selectedRows, index];
              setSelectedRows(newRows);
            } else {
              const newRows = [...selectedRows].filter((theIndex) => theIndex !== index);
              setSelectedRows(newRows);
            }
          }}
        />
      </div>
    </div>
  );
};

export default withRouter(InventoryList);
