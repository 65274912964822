import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Menu,
  MenuItem,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { BarGraph } from '../../../../components';

const useStyles = makeStyles((theme) => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  progress: {
    margin: theme.spacing(4),
    color: theme.palette.primary.main
  }
}));


const LatestSales = (props) => {
  const { className, dias, updateDias, loading, fetchData, ...rest } = props;
  const [ anchorEl, setAnchorEl ] = useState(null);

  const classes = useStyles();


  function handleClick(ev) {
    setAnchorEl(ev.currentTarget);
  }

  function handleClose(newDias) {
    if (newDias > 0) {
      updateDias(newDias);
    }
    setAnchorEl(null);
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <ClickAwayListener 
            onClickAway={handleClose}
          >
            <div>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                variant="text"
              >
                {`${dias} dias`}<ArrowDropDownIcon />
              </Button>
              <Menu
                anchorEl={anchorEl}
                id="dropdown-menu"
                keepMounted
                onClose={handleClose}
                open={Boolean(anchorEl)}
              >
                <MenuItem onClick={handleClose.bind(this, 7)}>7 dias</MenuItem>
                <MenuItem onClick={handleClose.bind(this, 15)}>15 dias</MenuItem>
                <MenuItem onClick={handleClose.bind(this, 30)}>30 dias</MenuItem>
                <MenuItem onClick={handleClose.bind(this, 60)}>60 dias</MenuItem>
                <MenuItem onClick={handleClose.bind(this, 90)}>90 dias</MenuItem>
              </Menu>
            </div>

          </ClickAwayListener>
        }
        title="Ventas Recientes $"
      />
      <Divider />
      {loading && !fetchData && <CircularProgress className={classes.progress} />}
      {!loading && fetchData  && 
      <CardContent>
        <div className={classes.chartContainer}>
          <BarGraph 
            data={fetchData}
          />
        </div>
      </CardContent>}
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          Resumen <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
}


LatestSales.propTypes = {
  className: PropTypes.string,
  dias: PropTypes.number.isRequired,
  fetchData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  updateDias: PropTypes.func.isRequired,
};

export default LatestSales;
