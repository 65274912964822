import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Menu,
  MenuItem,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { PieChart } from '../../../../components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: 400
  },
  actions: {
    justifyContent: 'flex-end'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  progress: {
    margin: theme.spacing(4),
    color: theme.palette.primary.main
  }
}));

const VentasPorcentuales = props => {
  const { className, ciudad, setCiudad, loading, fetchData, ...rest } = props;

  const classes = useStyles();
  const [ anchorEl, setAnchorEl ] = useState(null);

  function handleClick(ev) {
    setAnchorEl(ev.currentTarget);
  }

  function handleClose(newCity) {
    if (typeof newCity === 'string') {
      setCiudad(newCity);
    }
    setAnchorEl(null);
  }




  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <ClickAwayListener 
            onClickAway={handleClose}
          >
            <div>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                variant="text"
              >
                {`${ciudad}`}<ArrowDropDownIcon />
              </Button>
              <Menu
                anchorEl={anchorEl}
                id="dropdown-menu"
                keepMounted
                onClose={handleClose}
                open={Boolean(anchorEl)}
              >
                <MenuItem onClick={handleClose.bind(this, 'Quito')}>Quito</MenuItem>
                <MenuItem onClick={handleClose.bind(this, 'Guayaquil')}>Guayaquil</MenuItem>
              </Menu>
            </div>

          </ClickAwayListener>
        }
        title="Ventas Porcentuales %"
      />
      <Divider />
      <CardContent>
        {loading && <CircularProgress className={classes.progress} />}
        {!loading && fetchData &&      
        <div className={classes.chartContainer}>
          <PieChart
            data={fetchData}
          />
        </div>
        }

      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          Resumen <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

VentasPorcentuales.propTypes = {
  ciudad: PropTypes.string.isRequired,
  className: PropTypes.string,
  fetchData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  setCiudad: PropTypes.func.isRequired
};

export default VentasPorcentuales;
