import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import inventoryReducer from './inventory.reducer';
import authReducer from './auth.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist : ['inventoryReducer','authReducer']
}

const rootReducer = combineReducers({
  inventoryReducer,
  authReducer
});

export default persistReducer(persistConfig , rootReducer);