import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table, 
  TableBody, 
  TableCell, 
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  noInventory:{
    margin: '10px 0 0 10px'
  }
}));

const ProvidersTable = props => {

  const { 
    className,
    entries,
    totalItems,
    rowsPerPage,
    page,
    setRowsPerPage,
    handlePageChange,
    ...rest 
  } = props;

  const classes = useStyles();

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return ( 
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Razón social</TableCell>
                  <TableCell>Teléfono</TableCell>
                  <TableCell>email</TableCell>
                  <TableCell>Direccion</TableCell>
                  <TableCell>RUC</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entries.length !== 0 ? 
                  <>
                    {entries.slice(0, rowsPerPage).map(entry => {
                      const entryData = entry.data;
                      const entryID = entry.id
                      return (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={entryID}
                        >
                          <TableCell>{entryData.razon_social}</TableCell>
                          <TableCell>{entryData.telefonos}</TableCell>
                          <TableCell>{entryData.email}</TableCell>
                          <TableCell>{entryData.direccion}</TableCell>
                          <TableCell>{entryData.ruc}</TableCell>
                        </TableRow>
                      )
                    })}
                  </>
                  : 
                  <>
                    <TableRow className={classes.tableRow}>
                      <Typography className={classes.noInventory}>
                        No hay proveedores a mostrar
                      </Typography>
                    </TableRow>
                  </>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalItems}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
}

ProvidersTable.propTypes = {
  className: PropTypes.string,
  entries: PropTypes.array.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
};
 
export default ProvidersTable;