import { Button, Card, CircularProgress,CardContent, Collapse, IconButton, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    width: 200,
    display: 'inline-block',
    marginTop: theme.spacing(1)
  },
  cardStyles: {
    position: 'absolute',
    width: '15rem',
    zIndex: 9
  },
  space: {
    marginTop: 10,
    marginBottom: 10
  },
  maginTop: {
    marginTop: '6rem'
  },
  mt5 : {
    marginTop: '20px'
  },
  inlineBlock: {
    display: 'inline-block',
    position: 'relative',
    bottom: 5,
  }
}));

const ProvidersToolbar = props => {
  const {
    className,
    setFilters,
    filters,
    searchUsingFilters,
    clearFilters,
    setShowRefresh,
    refreshProviders,
    messageRefresh,
    showRefresh,
    ...rest
  } = props;
    
  const classes = useStyles();
  const [open,setOpen] = React.useState(false)

  const handleClick = () =>{
    setOpen(!open)
  }
  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <List
          component="nav"
          {...rest}
          className={clsx(classes.root, className, classes.maginTop)}
        >
          <ListItem
            button
            onClick={handleClick}
          >
            <ListItemIcon>
              <div className={classes.icon}><FilterListIcon /></div>
            </ListItemIcon>
            <ListItemText primary={'Filtros'} />
            {open? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <List 
              component="div"
              disablePadding
            >
              <Card className={classes.cardStyles}>
                <CardContent>
                  <TextField
                    className={classes.space}
                    id="outlined-basic"
                    label="RUC"
                    variant="outlined"
                    value={filters.RUC}
                    onChange={handleChange}
                    name="RUC"
                  />
                  <TextField
                    className={classes.space}
                    id="outlined-basic"
                    label="Nombre"
                    variant="outlined"
                    value={filters.providerName}
                    onChange={handleChange}
                    name="providerName"
                  />
                  <div>
                    <Button
                      onClick={()=> searchUsingFilters(filters)}
                      color="primary"
                    >Filtrar</Button>
                    <Button
                      onClick={()=> clearFilters()}
                      color="inherit"
                    >Limpiar</Button>
                  </div>
                </CardContent>
              </Card>
            </List>
          </Collapse>
        </List>
      </div>
      <div className={classes.inlineBlock}>
        <IconButton
          onClick={() => {
            refreshProviders();
            setShowRefresh(true);
          }}
        >
          <RefreshIcon />
        </IconButton>
      </div>
      <div className={classes.mt5}>
        { 
          showRefresh ?
            <>
              <CircularProgress color="secondary" />
            </> 
            : 
            <Typography
              variant="body1"
              color="secondary"
            >{messageRefresh}</Typography>
        }
      </div>
    </div>
  );
}

ProvidersToolbar.propTypes = {
  className: PropTypes.string,
  clearFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  messageRefresh: PropTypes.string.isRequired,
  refreshProviders: PropTypes.func.isRequired,
  searchUsingFilters: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  setShowRefresh: PropTypes.func.isRequired,
  showRefresh: PropTypes.bool.isRequired,
};
 
export default ProvidersToolbar;