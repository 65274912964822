import { useTheme } from '@material-ui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import { options } from './chart';

function BarGraph(props) {
  const theme = useTheme();

  function parseData(data) {
    if (!(data.data)) {
      return null;
    }
    const labels = data.labels.map((date) => moment(date).format('MMM D'));
    return {
      labels,
      datasets: [
        {
          label: 'Cuota',
          backgroundColor: theme.palette.neutral,
          data: data.data.cuota
        },
        {
          label: 'Venta',
          backgroundColor: theme.palette.primary.main,
          data: data.data.actual
        }
      ]
    };
  }

  return (
    <Bar
      data={parseData(props.data)}
      options={options}
    />
  );
}

BarGraph.propTypes = {
  data: PropTypes.object
}


export default BarGraph;