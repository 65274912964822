import { Button } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  dateRow: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-around'
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginLeft: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const InventoryToolbar = props => {
  const { className, onExportClick, onDateSearch, selectedRows, endDate, startDate, onSelectEndDate, onSelectStartDate, ...rest } = props;

  const handleDateChange = (date) => {
    onSelectStartDate(date);
  };
  const handleEndDateChange = (date) => {
    onSelectEndDate(date);
  }


  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <div className={classes.dateRow}>
          <KeyboardDatePicker
            disableFuture
            disableToolbar
            variant="inline"
            format="MM/DD/YYYY"
            margin="normal"
            id="date-picker-inline"
            label="Fecha Inicio"
            value={startDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardDatePicker
            disableFuture
            disableToolbar
            variant="inline"
            format="MM/DD/YYYY"
            margin="normal"
            id="date-picker-inline"
            label="Fecha Final"
            value={endDate}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </div>

        <Button
          color="secondary"
          variant="outlined"
          onClick={onDateSearch}
        >
          Buscar
        </Button>
        <span className={classes.spacer} />
        <Link to="/inventarios/toma-fisica/nuevo">
          <Button
            color="primary"
            variant="contained"
          >
            Nueva Toma Física
          </Button>
        </Link>
        {
          selectedRows.length > 0 && (
            <Button
              color="secondary"
              variant="outlined"
              className={classes.exportButton}
              onClick={onExportClick}
            >
              Exportar
            </Button>
          )
        }
      </div>
    </div>
  );
};

InventoryToolbar.propTypes = {
  className: PropTypes.string,
  endDate: PropTypes.any.isRequired,
  onDateSearch: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired,
  onSelectEndDate: PropTypes.func.isRequired,
  onSelectStartDate: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  startDate: PropTypes.any.isRequired,
};

export default InventoryToolbar;
