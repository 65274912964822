/* eslint-disable array-callback-return */
import { Button, Collapse, colors, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import { Link, NavLink as RouterLink } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();
  const [open,setOpen] = useState({});

  React.useEffect(()=>{
    const auxObj = {};
    pages.map( (page,i) => {
      auxObj[`openItem${i}`] = false;
    })
    setOpen(auxObj)
  },[pages])

  const handleClick = (i) =>{
    const auxObj = {...open};
    const openOrClose = !auxObj[`openItem${i}`]
    auxObj[`openItem${i}`] = openOrClose; 
    setOpen(auxObj)
  }

  return (
    <List
      component="nav"
      {...rest}
      className={clsx(classes.root, className)}
    >
      {pages.map((page,i) => (
        page.children.lenth === 0 ?
          <ListItem
            className={classes.item}
            disableGutters
            key={page.title}
          >
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
            >
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          </ListItem>
          :
          <>
            <ListItem
              button 
              onClick={() => handleClick(i)}
            >
              <ListItemIcon>
                <div className={classes.icon}>{page.icon}</div>
              </ListItemIcon>
              <ListItemText primary={page.title} />
              {open[`openItem${i}`] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={open[`openItem${i}`]}
              timeout="auto"
              unmountOnExit
            >
              <List
                component="div"
                disablePadding
              >
                {page.children.map( subMenu =>(
                  <ListItem
                    button
                    className={classes.nested}
                  >
                    <Link to={subMenu.href}>
                      <ListItemText primary={`${subMenu.title}`} />
                    </Link>
                  </ListItem>
                ) )}
              </List>
            </Collapse>
          </>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
