import { inventoryTypeEnum } from 'common/constants';
import clienteAxios from 'config/Axios';
import { reject } from 'lodash';

import { db } from '../../../firebase';
import { inventarioTomaFisica } from '../../../firebase/collections';


/**
 * Retrieves all the items from collection based on condition
 * @param {*} collection 
 * @param {*} condition 
 * @returns 
 */
export const getTotalItems = (
  collection = inventarioTomaFisica,
  condition = {
    field: 'type',
    operator: '==',
    value: inventoryTypeEnum.TOMA_FISICA
  }
) => {
  return new Promise( (resolve) =>{
    const query = db.collection(collection).where(condition.field,condition.operator,condition.value);
    query.get()
      .then(querySnapshot => {
        const docs = querySnapshot.docs.map((theDoc) => ({
          data: theDoc.data(),
          id: theDoc.id
        }))
        const totalItems2 = querySnapshot.size;
        resolve({ totalItems2, docs });
      }).catch(err =>{
        reject('Error en el método getTotalItems ' , err);
      })
  })
}

export const getEntries = (
  rowsPerPage = 10,
  orderBy = {
    field: 'lastSaved',
    direction: 'desc'
  },
  page = 0,
  collection = inventarioTomaFisica,
  conditions = [{
    field: 'type',
    operator: '==',
    value: inventoryTypeEnum.TOMA_FISICA
  }]
) => {
  return new Promise( (resolve,reject) =>{
    const finalRowsPerPage = page === 0 ? rowsPerPage : rowsPerPage * page;
    let query = db.collection(collection);

    for (const cond of conditions) {
      query = query.where(cond.field, cond.operator, cond.value);
    }
    query = query
      .limit(finalRowsPerPage)
      .orderBy(orderBy.field,orderBy.direction);


    if(page === 0){
      query.get()
        .then(querySnapshot => {
          const data = querySnapshot.docs.map(doc => {
            return {
              data: doc.data(),
              id: doc.id
            }
          });
          const { size } = querySnapshot;
          resolve({ data, size: size + 1 })
        })
        .catch(function(error) {
          console.log('Error getting documents: ', error);
        });
    }else{
      query.get().then( (documentSnapshots) =>{
        var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        let newQuery = query;
        for (const cond of conditions) {
          newQuery = newQuery.where(cond.field, cond.operator, cond.value);
        }
        
        newQuery
          .startAfter(lastVisible)
          .limit(rowsPerPage)
          .get()
          .then(querySnapshot => {
            const data = querySnapshot.docs.map(doc => {
              return {
                data: doc.data(),
                id: doc.id
              }
            });
            const { size } = querySnapshot;
            const totalSize = (size * (page + 1)) + 1;
            resolve({data, size: totalSize});
          })
          .catch(function(error) {
            reject('Error en el metodo get entries ' + error)
          });          
      });
    }
  })
}

export const getDocument = (
  collection = inventarioTomaFisica,
  docID,
) => {
  return new Promise( (resolve,reject) =>{
    db.collection(collection)
      .doc(docID)
      .get()
      .then(doc => {
        const data = doc.data();
        resolve({data});
      })
      .catch(err =>{
        reject('Error en el metodo get Document ' + err)
      });
  });
}

export const updateDocument = (
  collection = inventarioTomaFisica,
  docID,
  stateUpdated
) => {
  return new Promise( (resolve,reject) => {
    const document = db.collection(collection).doc(docID);

    document.update(stateUpdated).then(()=>{
      resolve(true);
    }).catch(err => {
      console.log(err);
      reject(false);
    });

  }) 
}

export const saveDocument = (
  collection = inventarioTomaFisica,
  state
) =>{
  return new Promise( (resolve,reject) => {
    db.collection(collection).add(state).then((docRef)=>{
      resolve(docRef.id)
    }).catch(err=>{
      reject('Hubo un error en el metodo saveDocument' + err)
    });
  })
}

export const getProductsFirestore = async (rowsPerPage,page,idLastProduct) => {
  return await clienteAxios.get(`/getProducts?rowsPerPage=${rowsPerPage}&page=${page}&idLastProduct=${idLastProduct}`);
}

export const getVariants = async () => {
  return await clienteAxios.get('/getVariants');
}

export const getProductVariants = async (id) => {
  return await clienteAxios.get(`/getProductVariants?searchId=${id}`);
}

export const loadDataToFirestore = async () =>{
  return await clienteAxios.post('/loadDataToFirestore');
}
