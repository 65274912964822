/* eslint-disable react/prop-types */
import { AppBar, Hidden, IconButton, Toolbar } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/styles';
import { signOutAction } from 'actions/auth.actions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import { AuthContext } from '../../../../lib';

// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  button: {
    color: '#ffff'
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, history, ...rest } = props;

  const { setAuthenticated, setAuthBody } = useContext(AuthContext);

  const classes = useStyles();
  const dipatch = useDispatch();
  const signOut = () => dipatch( signOutAction(history) )

  // const [notifications] = useState([]);
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img 
            src={'/images/logos/LOGOTIPO BLANCO.png'}
            width="250px" 
            alt="Logo Rack Shoes" 
            height="50px"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={() => {
              signOut()
              setAuthenticated(false);
              setAuthBody(null);
            }}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default withRouter(Topbar);
