import { useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Pie } from 'react-chartjs-2';

const options = {
  legend: {
    display: true
  },
  responsive: true,
  maintainAspectRatio: false,
  cutoutPercentage: 0,
  layout: { padding: 0 }
};

function PieChart(props) {
  const theme = useTheme();

  function parseData(data) {
    if (!data || !data.labels || !data.data) {
      return null;
    }
    const newData = data.data.map((d) => d * 100);
    return {
      labels: data.labels,
      datasets: [
        {
          data: newData,
          backgroundColor: [
            // TODO[sebastian]: Dynamically generate colors
            theme.palette.primary.main,
            theme.palette.error.main,
            theme.palette.warning.main,
            'green',
            'red',
            'blue'
          ],
          borderWidth: 8,
          borderColor: theme.palette.white,
          hoverBorderColor: theme.palette.white
        }
      ]
    }
  }

  const data = parseData(props.data);

  return(
    <Pie
      data={data}
      options={options}
    />
  )
}


PieChart.propTypes = {
  data: PropTypes.object
}

export default PieChart;