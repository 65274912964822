import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux'

const PrivateRouteWithLayout = ({ render, layout: Layout, component: Component, ...routeProps}) => {

  const authenticated = useSelector( state => state.authReducer.authenticated )
  
  return (
    <Route
      {...routeProps}
      render={() => (
        authenticated ?
          <Layout>
            <Component {...routeProps} />
          </Layout>
          : <Redirect to="/sign-in"/>
      )}
    />
  )
}

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  render: PropTypes.func.isRequired
}
export default PrivateRouteWithLayout;
