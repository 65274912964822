import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import EntryRemittanceDetails from 'views/Inventory/EntryRemittance/EntryRemittanceDetails';
import { entryRemittanceUrl, inventoryUrl, newEntryRemittanceUrl, newTagUrl, tagUrl } from 'views/Inventory/urls';
import { providersUrl } from 'views/Providers/urls';
import { singnInUrl } from 'views/SignIn/urls';

import { PrivateRouteWithLayout, RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  Account as AccountView,
  Dashboard as DashboardView,
  Inventory as InventoryView,
  InventoryDetails as InventoryDetailsView,
  NewStockCount as NewStockCountView,
  NotFound as NotFoundView,
  Settings as SettingsView,
  SignIn as SignInView,
  SignUp as SignUpView,
  Typography as TypographyView,
  UserList as UserListView,
  Ventas as VentasView,
  EntryRemittanceList,
  NewEntryRemittance,
  TagView,
  NewTagView,
  ProvidersView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to={singnInUrl}
      />
      <PrivateRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <PrivateRouteWithLayout
        component={VentasView}
        exact
        layout={MainLayout}
        path="/ventas"
      />
      <PrivateRouteWithLayout
        component={NewStockCountView}
        exact
        layout={MainLayout}
        path={`${inventoryUrl}/nuevo`}
      />
      <PrivateRouteWithLayout
        component={NewEntryRemittance}
        exact
        layout={MainLayout}
        path={newEntryRemittanceUrl}
      />
      <PrivateRouteWithLayout
        component={NewTagView}
        exact
        layout={MainLayout}
        path={newTagUrl}
      />
      <PrivateRouteWithLayout
        component={InventoryView}
        exact
        layout={MainLayout}
        path={inventoryUrl}
      />
      <PrivateRouteWithLayout
        component={TagView}
        exact
        layout={MainLayout}
        path={tagUrl}
      />
      <PrivateRouteWithLayout
        component={ProvidersView}
        exact
        layout={MainLayout}
        path={providersUrl}
      />
      <PrivateRouteWithLayout
        component={InventoryDetailsView}
        exact
        layout={MainLayout}
        path={`${inventoryUrl}/:id`}
      />
      <PrivateRouteWithLayout
        component={EntryRemittanceDetails}
        exact
        layout={MainLayout}
        path={`${entryRemittanceUrl}/:id`}
      />
      <PrivateRouteWithLayout
        component={EntryRemittanceList}
        exact
        layout={MainLayout}
        path={entryRemittanceUrl}
      />
      <PrivateRouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/clientes"
      />
      <PrivateRouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/facturas"
      />
      <PrivateRouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <PrivateRouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/cuenta"
      />
      <PrivateRouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/ajustes"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path={singnInUrl}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
