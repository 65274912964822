export const groupBarcodeItems = (items) => {
  const finalItems = items.reduce((previous, nextItem) => {
    const newItems = [...previous];
    const upperValue = nextItem.barcode.toUpperCase();
    const foundIndex = previous.findIndex((item) => item && item.barcode === upperValue);
    if (foundIndex === -1) {
      newItems.push({
        updatedAt: nextItem.updatedAt,
        barcode: upperValue,
        count: 1
      });
    } else {
      // Update the field
      const currentValue = newItems[foundIndex];
      newItems[foundIndex] = {
        updatedAt: currentValue.updatedAt > nextItem.updatedAt ? currentValue.updatedAt : nextItem.updatedAt,
        barcode: upperValue,
        count: currentValue.count + 1
      };
    }

    newItems.sort((a, b) => {
      return b.updatedAt - a.updatedAt;
    });

    return newItems;

  }, []);
  return finalItems;
}
