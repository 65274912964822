import { Avatar, Card, CardContent, CircularProgress, Grid, LinearProgress, Typography } from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  progress: {
    marginTop: theme.spacing(3)
  },
  circularProgress: {
    margin: theme.spacing(4),
    color: theme.palette.primary.main
  }
}));

const TasksProgress = props => {
  const { className, loading, cumplimiento, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              CUOTA ALCANZADA
            </Typography>
            {loading && <CircularProgress className={classes.circularProgress} />}
            {!loading && <Typography variant="h3">{`${cumplimiento * 100} %`}</Typography>}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <InsertChartIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <LinearProgress
          className={classes.progress}
          value={cumplimiento ? cumplimiento * 100 : 0}
          variant="determinate"
        />
      </CardContent>
    </Card>
  );
};

TasksProgress.propTypes = {
  className: PropTypes.string,
  cumplimiento: PropTypes.number,
  loading: PropTypes.bool.isRequired
};

export default TasksProgress;
