import 'moment/locale/es';
import 'moment/locale/es';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useState } from 'react';

import { useFetch } from '../../lib';
import {
  Budget,
  LatestOrders,
  LatestProducts,
  LatestSales,
  TasksProgress,
  TotalItems,
  TotalProfit,
  VentasPorcentuales,
} from './components';

moment.locale('es');

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [ dias, setDias ] = useState(7);
  const [ ciudad, setCiudad ] = useState('Quito');
  const [ resultado, loading ] = useFetch(`inicio?dias=${dias}&ciudad=${ciudad}`);
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Budget 
            budget={resultado.headers? resultado.headers.cuota : null}
            loading={loading}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalItems
            items={resultado.headers ? resultado.headers.items_vendidos : null}
            loading={loading}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TasksProgress 
            cumplimiento={resultado.headers ? resultado.headers.porcentaje_cuota_mensual : null}
            loading={loading}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalProfit 
            loading={loading}
            ventas={resultado.headers ? resultado.headers.ventas_totales : null}
          />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestSales 
            dias={dias}
            fetchData={resultado.ventas_recientes}
            loading={loading}
            updateDias={setDias}
          />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <VentasPorcentuales
            ciudad={ciudad}
            fetchData={resultado.ventas_porcentuales} 
            loading={loading}
            setCiudad={setCiudad}
          />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <LatestProducts 
            loading={loading}
            products={resultado.nuevos_productos}
          />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestOrders 
            loading={loading}
            orders={resultado.ultimas_compras}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
