export const Constants = {
  emailRegExp : new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i),
}

export const inventoryStatusEnum = {
  IN_PROGESS: 'IN_PROGRESS',
  COMPLETED : 'COMPLETED'
}

export const inventoryTypeEnum = {
  TOMA_FISICA: 'TOMA_FISICA',
  INGRESO: 'INGRESO',
  EGRESO: 'EGRESO',
  ETIQUETA: 'ETIQUETA'
}