import { GUARDAR_TOMA_FISICA } from '../types/inventory.types';

export const saveInventoryAction = ( state ) =>{
  return async (dispatch)=>{
    try {
      dispatch( saveInventory(state) );  
    } catch (error) {
      console.error(error);
    }
  }
}

export const saveInventory = (state) => ({
  type: GUARDAR_TOMA_FISICA,
  payload: state
});
